<template>
<vue-load @loadData="loadData">
  <div class="container">
    <room-type :typeList="roomTypeList" :currentType="roomType" @changeType="changeType"></room-type>
    <room-state-list :stateList="roomStateList" :currentState="roomState" @changeState="changeState"></room-state-list>
    <room-list :dataList="groupingRoomList"  :state="roomState" @showRoomDetail="showRoomDetail" @handlePull="handlePull"></room-list>
           <room-detail v-if="showDetail"
                         @closeWindow="showDetail = false"
                         :roomDetail="roomDetail">
            </room-detail>
  </div>
</vue-load>
</template>

<script>
import table from "@/api/table"
import {mapState} from 'vuex';
// import RoomDetail from "./room-detail";

let timeName = null
export default{
  name:"room",
  data(){
    return{
      roomType:"全部",
      roomTypeList:[],
      roomState:2,
      roomStateList: [
        {id:2,label:'进客',sumNum:0},
        {id:1,label:'空闲',sumNum:0},
        {id:3,label:'脏房',sumNum:0},
        {id:4,label:'故障',sumNum:0},
        {id:'total',label:'全部',sumNum:0},
      ],
      //房间详情
      roomDetail:null,
      showDetail:false,
      groupingRoomList:null,
      //刷新间隔时间 15秒钟
      loadTime:15000,
    }
  },
  computed:{
    ...mapState(['userStatus'])
  },
  components:{
    'vue-load':() => import('@/components/vue-load'),
    'room-type':() => import('./room-type'),
    'room-state-list':() => import('./room-state-list'),
    'room-list':() => import('./room-list'),
    'room-detail':() => import('./room-detail')
  },
  watch:{
    roomType:{
      handler(){
        this.loadData(true)
      },
      deep:true
    },
    roomState:{
      handler(){
        this.loadData(true)
      },
      deep:true
    },
    'userStatus.curs_node_id_2':{
        handler(){
          this.loadData(true)
        },
        deep:true
    }
  },
  methods:{
    /*切换类型*/
    changeType(type){
      this.roomType = type
      console.log(this.roomType)
    },
    /*切换状态*/
    changeState(state){
      this.roomState = state
    },
    /*显示详情 */
    showRoomDetail(room){
      this.roomDetail = room
      this.showDetail = true
    },
    /*统计各状态人数*/
    getStateSumNum(){
      let {roomStateList,stateTotal} = this
      for(let state of roomStateList){
        for(let key in stateTotal){
          if(state.id == key){
            state.sumNum = stateTotal[key]
          }
        }
      }
    },
    /*请求数据*/
    loadData(loading){
      this.getRoomTypeList(loading).then(() =>{
        this.getRoomList(loading).then(() =>{
          this.getStateSumNum()
        }).catch(err =>{
        })
      })
    },
    /*获取房间类型*/
    getRoomTypeList(loading){
      let {roomType} = this
      return new Promise((resolve,reject) =>{
        table.getRoomTypeList().then(res =>{
          let typeList = ['全部']
          let typeData = res.data
          for(let key in typeData.room_type){
            typeList.push(key)
          }
          this.roomTypeList = typeList
          this.stateTotal = typeData.room_total[roomType]
          resolve()
        }).catch(err =>{
          Object.assign(this.$data, this.$options.data());
          console.log("err",err.msg)
          reject()
        })
      })
    },
    			/*获取技师列表*/
    getRoomList(loading){
      let {roomType,roomState} = this
      let type = roomType=="全部"?'':roomType
      let state = roomState=="total"?0:roomState
      let params = {
        roomType:type,
        roomState:state,
      }
      return new Promise((resolve,reject) =>{
        table.getRoomList(params).then(res =>{
          this.groupingRoomList = res.data
          resolve()
        }).catch(err =>{
          console.log('err',err.msg)
          reject()
        })
      })
    }
  },
  mounted() {
    this.$store.commit('updateIsShowTabBar',true)
    this.$store.commit("updateIsShowPickStore",true)
  },
  created(){
    this.loadData(true)
    // let {loadTime} = this

    // if(this.timer){
    //   clearInterval(this.timer)
    // }else{
    //   this.timeName = setInterval(()=>{
    //     this.loadData()
    //   },loadTime)
    // }
  },
  // destroyed(){
  // 	clearInterval(this.timeName)
  // 	this.timeName = null
  // }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .container{
        display:flex;
        flex-direction:column;
        height:calc(100vh - 100px);
        overflow:hidden;
    }
</style>
